<template>
  <div>
    <div class="intro">
      <div class="zedrt">
        <div class="tiA"></div>
        <div class="typeface">
          <p>{{ companyData.copyTitle }}</p>
        </div>
        <div class="tiB"></div>
      </div>
    </div>

    <div
      data-aos="fade-up"
      data-aos-once="true"
      data-aos-offset="0"
      data-aos-delay="0"
      data-aos-anchor-placement:="bottom-bottom"
    >
      <div class="tmp">
        <div class="tmp-img">
          <div>
            <baidumap :coordinate="center" />
          </div>
          <p class="dept">上海</p>
        </div>
        <div class="tmptit">
          <p>地址：上海市普陀区交通路4711号李子园大厦1606室</p>
          <p>电话：021-56530009</p>
          <p>邮箱：support@aventura.net.cn</p>
        </div>
      </div>

      <div class="tmp">
        <div class="tmp-img">
          <baidumap :coordinate="beijing" />
          <p class="dept">北京</p>
        </div>
        <div class="tmptit">
          <p>地址：北京市朝阳区北辰东路8号汇园酒店公寓M座902室</p>
          <p>电话：010-84970965</p>
          <p>邮箱：tshen@aventura.net.cn</p>
        </div>
      </div>

      <div class="tmp">
        <div class="tmp-img">
          <baidumap :coordinate="wuhan" />
          <p class="dept">武汉</p>
        </div>
        <div class="tmptit">
          <p>地址：武汉东湖新技术开发区光谷大道70号（精伦园）2栋（C区）C307</p>
          <p>电话：027-87057188</p>
          <p>邮箱：bus@aventura.net.cn</p>
        </div>
      </div>
    </div>

    <div class="intro">
      <div class="zedrt">
        <div class="tiA"></div>

        <div class="typeface">
          <p>公司风采</p>
        </div>
        <div class="tiB"></div>
      </div>
    </div>

    <div
      class="tema"
      data-aos="fade-up"
      data-aos-once="true"
      data-aos-offset="0"
      data-aos-delay="0"
      data-aos-anchor-placement:="bottom-bottom"
    >
      <div class="card">
        <img src="../../assets/Introduce/team/mihayou1.png" alt="" />
        <div class="card-p">
          <div class="huang"></div>
          <p class="tipou">上海公司秋季团建</p>
        </div>
      </div>
      <div class="card">
        <img src="../../assets/Introduce/team/mihayou2.png" alt="" />

        <div class="card-p">
          <div class="huang"></div>
          <p class="tipou">武汉公司户外活动</p>
        </div>
      </div>
      <div class="card">
        <img src="../../assets/Introduce/team/mihayou3.png" alt="" />

        <div class="card-p">
          <div class="huang"></div>
          <p class="tipou">园区定向赛大型活动</p>
        </div>
      </div>
    </div>

    <div style="height: 121px"></div>
  </div>
</template>

<script>
import baidumap from "@/components/map/baidumap.vue";
import AOS from "aos";
import {company} from "@/api";

export default {
  components: {
    baidumap,
  },
  data() {
    return {
      center: [
        {
          lng: 121.39725,
          lat: 31.273951,
        },
        { zomm: 18 },
      ],
      beijing: [
        {
          lng: 116.408513,
          lat: 39.999449,
        },
        { zomm: 18 },
      ],
      queryForm:{
        currentPage: 1,
        pageSize: 10,
        isEnable: -1
      },
      wuhan: [
        {
          lng: 114.429506,
          lat: 30.500105,
        },
        { zomm: 17 },
      ],
      companyData: {}
    };
  },
  created() {
    company(this.queryForm).then(res => {
      this.companyData = res.data[2]
    })
  },

  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>

<style scoped lang="less">
.tipou {
  color: #333333;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.huang {
  width: 81px;
  height: 6px;
  background: #f3dc36;
  border-radius: 6px;
  margin: 41px auto;
}
.card > img {
  width: 100%;
  height: 180px;
}
.card-p {
  width: 100%;
  height: 75px;
  text-align: center;
  line-height: 75px;

  border: 1px solid #eeeeee;
  position: relative;
}
.card {
  width: 284px;
}
.tema {
  width: 900px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.tmptit {
  color: #636363;
  font-size: 12px;
  margin-left: 34px;
  line-height: 22px;
  font-weight: 600;
}
.dept {
  position: absolute;
  top: -3px;
  left: 9px;
  width: 83px;
  height: 34px;
  background-color: #f44336;
  color: #fff;
  line-height: 34px;
  text-align: center;
}
.tmp-img {
  width: 336px;
  height: 122px;
  background-color: #7d0900;
  position: relative;
}
.tmp {
  width: 834px;
  height: 122px;
  box-shadow: 0px 0px 5px 0px rgba(125, 9, 0, 0.24);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 81px;
}

.intro {
  margin: 91px 0;
}

//end
.typeface {
  color: #282828;
  font-size: 40px;
  margin: 0 29px;
}
.zedrt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 81px;
}
.tiB {
  width: 120px;
  height: 9px;
  background: #0f4f7a;
  border-radius: 6px;
}
.tiA {
  width: 120px;
  height: 9px;
  background: #456e71;
  border-radius: 6px;
}
// 标题
</style>
