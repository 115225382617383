<template>
  <div class="beji">
    <div class="boutt">
      <div class="logez">
        <img src="../assets/logez.png" />
        <p >增值电信业务经营许可证：沪B2-20220514 </p>
        <p >Copyright © 2012-2022  &nbsp;<span @click="locat">沪ICP备19038355号-1</span></p>
      </div>

      <div class="contact">
        <h2>联系我们</h2>
        <p class="cengmi">祺机(上海)科技股份有限公司</p>
        <div class="chtent">
           <p>公司地址：上海市普陀区交通路4711号李子园大厦1606室</p>
        <p>服务热线：400 089 0369</p>
        <p>联系电话：021-56530009</p>
        <p>邮&emsp;&emsp;编：200331</p>
        <p>邮&emsp;&emsp;箱：support@aventura.net.cn</p>

        </div>
       
      </div>

      <div class="official">
        <div class="buttImg"><img src="../assets/shanghaigz.jpg" alt="" /></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      weixin: 0,
    };
  },

  methods: {
    locat(){
  window.open("https://beian.miit.gov.cn/", '_blank')
    },
    mouseOver(num) {
      this.weixin = num;
    },
    mouseLeave(num) {
      this.weixin = num;
    },
  },
};
</script>

<style scoped>
.cengmi{
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 25px;

}

.buttImg > img {
  width: 128px;
  height: 128px;
}
.jiaz {
  margin: 0px;
  border-width: 10px;
  border-style: solid;
  border-color: #fff transparent transparent;
  padding: 0px;
  width: 0px;
  height: 0px;
  margin: 0 auto;
  margin-top: -5px;
}
.imggz {
  margin-bottom: 10px;
  margin-top: -130px;
  z-index: 99;
}

.imggz > img {
  width: 100px;
  height: 100px;
}
.account {
  text-align: center;
  margin-left: 18px;
  margin-right: 39px;
}
.but {
  width: 156px;
  height: 43px;
  border: 1px solid #ffffff;
  background-color: #f44336;
  color: #fff;
  font-size: 18px;
  margin-top: 54px;
}
.official {
  display: flex;
  align-items: center;
  justify-content: center;
}
.account > p {
  margin-top: 18px;
}
.we-img > img {
  width: 42px;
  height: 42px;
}
.we-img {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.contact > h2 {
  margin-bottom: 12px;
  font-size: 22px;
}
.chtent > p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 11px;
}
.logez > img {
  width: 115px;
  height: 47px;
  margin-bottom: 152px;

}
.logez > p>span{
   cursor:pointer;/*鼠标变小手*/

}
.logez > p {
  font-size: 16px;
  font-weight: 500;
}
.boutt {
  width: 75%;
  margin: 0 auto;
  height: 393px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.beji {
  width: 100%;
  height: 400px;
  background-color: #040404;
}
</style>